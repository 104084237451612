import { JobApplicantStatus } from '@factoryfixinc/ats-interfaces';

export const REJECTED_WITH_REASON = 'Reject w/ reason';
export const jobApplicationOptions: { title: string; value: JobApplicantStatus | string }[] = [
  { title: 'New Application', value: JobApplicantStatus.NEW },
  { title: 'Engaged', value: JobApplicantStatus.CLIENT },
  { title: 'Review', value: JobApplicantStatus.REVIEW },
  { title: 'Interview', value: JobApplicantStatus.INTERVIEW },
  { title: 'Offer', value: JobApplicantStatus.OFFER },
  { title: 'Hired', value: JobApplicantStatus.HIRED },
  { title: 'Reject', value: JobApplicantStatus.REJECTED },
  { title: REJECTED_WITH_REASON, value: REJECTED_WITH_REASON },
];

export const findJobApplicationStatusTitle = (status: JobApplicantStatus | string): string => {
  const option = jobApplicationOptions.find((option) => option.value === status);
  return option?.title || status;
};

export const OTHER_REASON = 'Other';
export const rejectionReasons: string[] = [
  'Lacks skills/qualifications',
  'Located too far away',
  'Unresponsive',
  'Position no longer open',
  'No show for interview',
  OTHER_REASON,
];
// Standard rejection reasons are used in the Radiogroup for rejecting an application and it requires a label and a value
export const standardRejectionReasons: { label: string; value: string }[] = rejectionReasons.map(
  (reason) => ({ label: reason, value: reason }),
);
