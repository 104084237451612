import { defineStore } from 'pinia';
import { ref } from 'vue';
import type {
  ConversationIndex,
  SearchConversationIndex,
} from '@/core/conversations/conversation-index/conversation-index.type';
import { JobApplicantStatus } from '@factoryfixinc/ats-interfaces';
import { SearchConversationOrderByFields } from '@/core/conversations/conversation-index/conversation-index.type';

export const useConversationIndexStore = defineStore(
  'conversationIndex',
  () => {
    const selectedConversationIndex = ref<ConversationIndex | null>();
    const conversationIndexes = ref<ConversationIndex[] | []>();
    const loadingConversationIndexes = ref<boolean>(true);
    const selectingConversationIndex = ref<boolean>(false);
    const selectedStatusList = ref<string[]>([]);
    const showSearchField = ref<boolean>(false);
    const visitedTabRouteName = ref<string>('');
    const selectedStatusFilters = ref<string[]>([]);
    const lastPageWithResults = ref<number | null>(null);

    const conversationSearch = ref<SearchConversationIndex>({
      onlyCurrentUser: false,
      onlyUnread: false,
      jobIds: [],
      conversationIds: [],
      applicationStatus: [
        JobApplicantStatus.NEW,
        JobApplicantStatus.CLIENT,
        JobApplicantStatus.REVIEW,
        JobApplicantStatus.INTERVIEW,
        JobApplicantStatus.OFFER,
        JobApplicantStatus.HIRED,
      ],
      textual: '',
      orderBy: {
        field: SearchConversationOrderByFields.LAST_MESSAGE_SENT_TS,
        direction: 'desc',
      },
      pagination: {
        page: 1,
        itemsPerPage: 25 as 25 | 50 | 75 | 100,
      },
    });

    return {
      conversationIndexes,
      selectedConversationIndex,
      conversationSearch,
      lastPageWithResults,
      loadingConversationIndexes,
      selectingConversationIndex,
      selectedStatusList,
      showSearchField,
      visitedTabRouteName,
      selectedStatusFilters,
    };
  },
  {
    persist: false,
  },
);
