<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    text: string;
    count: number;
    isSelected: boolean;
  }>(),
  {
    count: 0,
    isSelected: false,
  },
);

const displayText = computed(() => {
  return props.text;
});

const displayCount = computed(() => {
  return props.count > 0 ? props.count : '';
});

const isSelected = computed(() => {
  return props.isSelected;
});
</script>

<template>
  <div
    class="flex h-8 cursor-pointer items-center rounded-full border border-tint-80 px-3 py-1.5 transition-colors hover:border-tint-100 hover:bg-tint-20"
    :class="{ 'bg-shade-900 ': isSelected }"
  >
    <p
      class="m-0 text-nowrap font-sans text-xs font-normal transition-colors"
      :class="{
        'text-tint-0': isSelected,
        'text-shade-880': !isSelected,
      }"
    >
      {{ displayText }} <span class="font-bold">{{ displayCount }}</span>
    </p>
  </div>
</template>
