<script setup lang="ts">
import { computed, ref } from 'vue';
import { type Project, ProjectStatus } from '@factoryfixinc/ats-interfaces';
import { useRouter } from 'vue-router';

import ProjectEditSavedJobDialog from '@/components/Shared/Projects/ProjectEditSavedJobDialog.vue';
import ProjectCloneJobDialog from '@/components/Shared/Projects/ProjectCloneJobDialog.vue';

import MoreVerticalDots from '@/assets/svg/dots-vertical-20.svg?component';

import Rocket from '@/assets/svg/sourcing/rocket.svg';
import RocketCross from '@/assets/svg/sourcing/rocket-cross.svg';
import Duplicate from '@/assets/svg/sourcing/duplicate.svg';
import Edit from '@/assets/svg/edit-black.svg';
import Archive from '@/assets/svg/archive.svg';
import TrendyArrowUp from '@/assets/svg/sourcing/trendy-arrow-up.svg';
import DeleteRed from '@/assets/svg/sourcing/delete-red.svg';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ProjectService from '@/core/shared/project/project.service';

const props = defineProps<{
  modelValue: boolean;
  project: Project;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void;
}>();

const router = useRouter();

const projectService = new ProjectService();

const isEditJobDialogOpen = ref<boolean>(false);
const isCloneJobDialogOpen = ref<boolean>(false);
const isOpen = computed<boolean>(() => {
  return props.modelValue;
});
const projectStatus = computed(() => props.project.status);
const isCopilotActivated = computed(() => props.project.copilot);
const isArchived = computed(() => projectStatus.value === ProjectStatus.ARCHIVED);
const isLive = computed(() => projectStatus.value === ProjectStatus.LIVE);
const isLoading = ref<boolean>(false);

function handleUpdateModelValue(value: boolean) {
  emit('update:modelValue', value);
}

async function handleArchiveProject() {
  try {
    isLoading.value = true;
    const isCopilotActivated = props.project.copilot;
    await projectService.archiveProject(props.project.id, isCopilotActivated);
    await projectService.getProjectById(props.project.id);
    handleUpdateModelValue(false);
    TrackingService.trackAction(TrackingActionName.JOB_ARCHIVED);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to archive job. Please try again later.');
  } finally {
    isLoading.value = false;
  }
}

async function handleUnarchiveProject() {
  try {
    isLoading.value = true;
    await projectService.activateProject(props.project.id);
    await projectService.getProjectById(props.project.id);
    handleUpdateModelValue(false);
    TrackingService.trackAction(TrackingActionName.JOB_UNARCHIVED);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to unarchive job. Please try again later.');
  } finally {
    isLoading.value = false;
  }
}

async function handleDeleteProject() {
  try {
    isLoading.value = true;
    await projectService.deleteProject(props.project.id);
    handleUpdateModelValue(false);
    await router.push('/sourcing');
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to delete job. Please try again later.');
  } finally {
    isLoading.value = false;
  }
}

async function handleDeactivateCopilot() {
  try {
    isLoading.value = true;
    await projectService.updateProjectCopilotStatus(props.project.id, false);
    await projectService.getProjectById(props.project.id);
    handleUpdateModelValue(false);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to disable Copilot. Please try again later.');
  } finally {
    isLoading.value = false;
  }
}

// job flow redirects
async function activateCopilot() {
  await TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_STARTED, {
    source: 'action_menu',
  });
  await router.push(`/jobs/${props.project.id}/copilot-activation`);
}

// Action Dialogs
function openEditJob() {
  isEditJobDialogOpen.value = true;
  TrackingService.trackAction(TrackingActionName.JOB_EDIT_STARTED, {
    copilot_existing: isCopilotActivated.value,
  });
}

function openCloneJob() {
  isCloneJobDialogOpen.value = true;
  TrackingService.trackAction(TrackingActionName.JOB_DUPLICATE_STARTED, {
    copilot_existing: isCopilotActivated.value,
  });
}
</script>
<template>
  <span>
    <v-menu
      :model-value="isOpen"
      location="bottom"
      transition="slide-y-transition"
      :close-on-content-click="false"
      @update:model-value="handleUpdateModelValue"
    >
      <template #activator="{ props }">
        <div v-bind="props" class="flex cursor-pointer items-center justify-center rounded-md">
          <MoreVerticalDots
            height="20"
            width="20"
            :class="isOpen ? 'text-tint-60' : 'text-tint-400'"
            class="mt-[-1px] transition-colors hover:!text-tint-60"
          />
        </div>
      </template>

      <div class="mt-0.5 w-[200px] rounded-md bg-white py-2 shadow-menu">
        <!-- Live -->
        <template v-if="isLive">
          <!-- Enable Copilot -->
          <button
            v-if="!isCopilotActivated"
            class="mb-2 flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40"
            @click="activateCopilot"
          >
            <img :src="Rocket" alt="Rocket" width="20" height="20" />
            <p class="mb-0 ml-2 text-xs font-normal">Enable Copilot</p>
          </button>

          <!-- Disable Copilot -->
          <button
            v-if="isCopilotActivated"
            class="mb-2 flex h-8 w-full cursor-pointer items-center justify-start px-3 py-1 hover:bg-tint-40 disabled:opacity-50"
            :disabled="isLoading"
            @click="handleDeactivateCopilot"
          >
            <img :src="RocketCross" alt="Rocket" width="20" height="20" />
            <p class="mb-0 ml-2 text-xs font-normal">Disable Copilot</p>
          </button>

          <!-- Duplicate -->
          <button
            class="mb-2 flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40"
            @click="openCloneJob"
          >
            <img :src="Duplicate" alt="Duplicate" width="20" height="20" />
            <p class="mb-0 ml-2 text-xs font-normal">Duplicate and edit job</p>
          </button>

          <!-- Edit -->
          <button
            class="mb-2 flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40"
            @click="openEditJob"
          >
            <img :src="Edit" alt="Edit" width="20" height="20" class="stroke-black" />
            <p class="mb-0 ml-2 text-xs font-normal">Edit job</p>
          </button>

          <!-- Archive -->
          <button
            class="flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40 disabled:opacity-50"
            :disabled="isLoading"
            @click="handleArchiveProject"
          >
            <img :src="Archive" alt="Archive" width="20" height="20" />
            <p class="mb-0 ml-2 text-xs font-normal">Archive</p>
          </button>
        </template>

        <!-- Archived -->
        <template v-if="isArchived">
          <!-- Unarchive -->
          <button
            class="mb-2 flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40 disabled:opacity-50"
            :disabled="isLoading"
            @click="handleUnarchiveProject"
          >
            <img :src="TrendyArrowUp" alt="Trendy Arrow Up" width="20" height="20" />
            <p class="mb-0 ml-2 text-xs font-normal">Unarchive job</p>
          </button>

          <!-- Delete -->
          <button
            class="flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40 disabled:opacity-50"
            :disabled="isLoading"
            @click="handleDeleteProject"
          >
            <img :src="DeleteRed" alt="Delete" width="20" height="20" />
            <p class="mb-0 ml-2 text-xs font-normal">Delete job</p>
          </button>
        </template>
      </div>
    </v-menu>

    <ProjectEditSavedJobDialog :project="project" v-model="isEditJobDialogOpen" />
    <ProjectCloneJobDialog :project="project" v-model="isCloneJobDialogOpen" />
  </span>
</template>
