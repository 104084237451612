import type { JobApplicant } from '@factoryfixinc/ats-interfaces';
import { useApplicationScoreStore } from './application-score.store';
import type { CriteriaAssessment, ProScore } from './application-score.type';
import ApplicationScorePersistence from '@/core/conversations/application-score/application-score.persistence';
import type { ScoreReview } from '@/core/conversations/application-score/types/application-score-review.type';

export default class ApplicationScoreService {
  private readonly applicationScorePersistence = new ApplicationScorePersistence();
  private readonly applicationScoreStore = useApplicationScoreStore();

  public async fetchScoreDetails(
    jobApplicationId: JobApplicant['id'],
  ): Promise<ProScore | undefined> {
    return this.applicationScorePersistence.fetchScoreDetails(jobApplicationId);
  }

  public async getScoreDetail(jobApplicationId: JobApplicant['id']): Promise<ProScore | undefined> {
    const existingScoreDetails = this.applicationScoreStore.getScoreDetail(jobApplicationId);

    if (existingScoreDetails) {
      return existingScoreDetails;
    }

    const loadedScoreDetails = await this.fetchScoreDetails(jobApplicationId);
    return loadedScoreDetails;
  }

  public static formatScore(score?: number): string {
    if (score === undefined) {
      return 'N/A';
    }

    return score.toFixed(1);
  }

  public async sendScoreFeedback(review: ScoreReview): Promise<ScoreReview> {
    return this.applicationScorePersistence.sendScoreFeedback(review);
  }

  public static getScoreChipsInfo(
    score: ProScore | undefined,
  ): { title: string; text: string; color: CriteriaAssessment['relevantExperience']['color'] }[] {
    const chipsToDisplay: Record<
      string,
      keyof Omit<CriteriaAssessment, 'redFlagsOrConcerningPatterns' | 'relevantExperience'>
    > = {
      Longevity: 'jobHoppingRisk',
      'Pay expectations': 'payExpectations',
      'Shift expectations': 'shiftExpectations',
      'Ability to commute': 'commuteAbility',
    };
    const assessments = score?.criteriaAssessment;

    if (!assessments) {
      return [];
    }

    return Object.entries(chipsToDisplay).map(([label, criteria]) => {
      const assessment = assessments[criteria];

      return {
        title: label,
        text: assessment.assessment,
        color: assessment.color,
      };
    });
  }

  public static getScoreVersion(score: ProScore | undefined): string {
    const version = score?.version;

    if (!version) {
      // If the version is not available, default to v1
      return 'v1';
    }

    return version;
  }
}
