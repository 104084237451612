<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import DateUtils from '@/utils/date-utils';
import ApplicationScoreCard from '@/components/Conversations/Application/ApplicationScoreCard.vue';

import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';

import {
  type ConversationIndex,
  SearchConversationOrderByFields,
} from '@/core/conversations/conversation-index/conversation-index.type';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ProjectService from '@/core/shared/project/project.service';
import Checkbox from '@/components/Shared/Input/Checkbox/Checkbox.vue';

const props = defineProps<{
  conversationIndex: ConversationIndex;
}>();

const emit = defineEmits<{
  (e: 'onUpdateCheck', payload: boolean): void;
}>();

const projectService = new ProjectService();
const conversationIndexService = new ConversationIndexService();

const jobTitle = ref<string>();

const isSelected = computed(
  () =>
    conversationIndexService.selectedConversationIndex?.conversationId ===
    props.conversationIndex.conversationId,
);

const lastEmploymentDescription = computed(() => {
  let employmentDescription = '';

  if (props.conversationIndex.lastProJobTitle) {
    employmentDescription += props.conversationIndex.lastProJobTitle;

    if (props.conversationIndex.lastProEmployerName) {
      employmentDescription += ` at ${props.conversationIndex.lastProEmployerName}`;
    }
  }

  return employmentDescription;
});

const isBulkSelection = computed(() => props.conversationIndex.isBulkSelection || false);

const cardDate = computed(() => {
  const isCurrentSortByApplicationDate =
    conversationIndexService.conversationIndexSearch.orderBy.field ===
    SearchConversationOrderByFields.APPLICATION_APPLY_TS;
  let cardDate = props.conversationIndex.lastMessageSentTs || props.conversationIndex.updateTs;

  if (
    isCurrentSortByApplicationDate &&
    props.conversationIndex.applicationIndexes?.[0]?.applicationApplyTs
  ) {
    cardDate = props.conversationIndex.applicationIndexes?.[0]?.applicationApplyTs;
  }

  return DateUtils.formatTimeAndDateIfNotToday(cardDate);
});

const getProjectTitle = async (jobId?: number) => {
  if (!jobId) return '';
  return projectService.projectTitlesByJobId.get(jobId);
};

async function selectConversation(conversation: ConversationIndex) {
  try {
    await conversationIndexService.selectConversationIndex(conversation);

    TrackingService.trackAction(TrackingActionName.CONVERSATION_SELECTED, {
      job_ids: conversation.applicationIndexes?.map((application) => application.jobId),
      conversation_id: conversation.conversationId,
    });
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to load conversation information');
  }
}

onMounted(async () => {
  jobTitle.value = await getProjectTitle(props.conversationIndex.primaryApplicationIndex?.jobId);
});
</script>

<template>
  <div>
    <div
      @click="selectConversation(conversationIndex)"
      :id="`conversation-index-card-${conversationIndex.conversationId}`"
      :class="[isSelected ? 'border-highlight-0 border-white bg-highlight-0' : 'bg-white']"
      class="relative flex cursor-pointer flex-col rounded-lg bg-white p-3"
    >
      <img
        v-if="isSelected"
        class="absolute bottom-0 left-0 top-0"
        src="@/assets/svg/conversations/selected-conversation-vertical-line.svg"
        alt="Selected"
        width="8"
      />
      <div class="flex h-6 w-full items-center justify-between">
        <div
          class="position-relative mr-1 flex w-[220px] items-center truncate text-sm font-bold text-shade-900"
        >
          <Checkbox
            @click.stop="emit('onUpdateCheck', !conversationIndex.isBulkSelection)"
            class="bulk-selection-checkbox mr-2"
            :class="{ '!ml-0': isBulkSelection }"
            :model-value="isBulkSelection"
          />
          <span class="truncate">{{ conversationIndex.proName }}</span>
        </div>
        <div class="whitespace-nowrap text-right text-xs text-shade-820">
          {{ cardDate }}
        </div>
      </div>

      <div class="flex h-6 w-full items-center justify-between">
        <div v-if="lastEmploymentDescription" class="w-[250px] truncate text-xs text-shade-800">
          {{ lastEmploymentDescription }}
        </div>
        <div
          v-if="!conversationIndex.readStatus"
          class="h-4 w-4 rounded-full bg-highlight-500"
        ></div>
      </div>
      <ApplicationScoreCard
        :job-title="jobTitle || ''"
        :score="conversationIndex.primaryApplicationIndex?.score || 0"
        :is-selected="isSelected"
      />
    </div>
  </div>
</template>
<style scoped>
.bulk-selection-checkbox {
  @apply ml-[-24px] inline-block transition-all duration-300;
}
div[id^='conversation-index-card'] {
  @apply transition-colors duration-300;

  &:hover {
    @apply !bg-tint-40;

    .bulk-selection-checkbox {
      @apply ml-0;
    }
  }
}
</style>
