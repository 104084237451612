<script setup lang="ts">
const props = defineProps<{
  star: string;
  bg: string;
  width: number;
  height: number;
}>();
</script>

<template>
  <div :class="[`h-[${height}px]`, `w-[${width}px]`]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="4" :fill="props.bg" />
      <path
        d="M7.14314 4.62373C7.5318 3.97791 8.4681 3.97791 8.85676 4.62373L9.36147 5.46238C9.50109 5.6944 9.72884 5.85987 9.99265 5.92096L10.9462 6.14181C11.6805 6.31188 11.9699 7.20235 11.4758 7.77156L10.8341 8.51072C10.6566 8.71521 10.5696 8.98294 10.593 9.25272L10.6777 10.2279C10.7428 10.9788 9.98534 11.5291 9.29131 11.2351L8.39004 10.8533C8.14071 10.7476 7.8592 10.7476 7.60986 10.8533L6.7086 11.2351C6.01456 11.5291 5.25708 10.9788 5.32225 10.2279L5.40688 9.25272C5.43029 8.98294 5.3433 8.71521 5.16579 8.51072L4.52415 7.77156C4.03004 7.20235 4.31937 6.31188 5.05369 6.14181L6.00726 5.92096C6.27106 5.85987 6.49881 5.6944 6.63843 5.46239L7.14314 4.62373Z"
        :fill="props.star"
      />
    </svg>
  </div>
</template>
