<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void;
  (e: 'exit'): void;
  (e: 'cancel'): void;
}>();

const isOpen = computed<boolean>(() => {
  return props.modelValue;
});

function handleUpdateModelValue(value: boolean) {
  emit('update:modelValue', value);
}

function handleCancel() {
  emit('cancel');
  handleUpdateModelValue(false);
}

function handleExit() {
  emit('exit');
  handleUpdateModelValue(false);
}
</script>

<template>
  <v-dialog :model-value="isOpen" max-width="600px" height="226px" persistent>
    <div class="rounded-lg bg-white p-6">
      <p class="mb-4 font-serif text-2xl font-semibold leading-8 text-black">
        Exit Copilot activation?
      </p>
      <p class="font-sans text-base font-normal leading-7 text-black">
        Exit Copilot activation? Leaving now will cause your job to stay deactivated.
      </p>

      <!-- Actions -->
      <div class="flex items-center justify-end">
        <button
          class="rounded-md px-4 py-2.5 text-sm font-semibold leading-5 text-shade-880"
          @click="handleCancel"
        >
          Cancel
        </button>
        <button
          class="ml-4 rounded-md bg-highlight-600 px-4 py-2.5 text-sm font-semibold leading-5 text-tint-0"
          @click="handleExit"
        >
          Exit Copilot Activation
        </button>
      </div>
    </div>
  </v-dialog>
</template>
