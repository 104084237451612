import { useAtsApplicationScoringApi } from '@/composables/useApi';
import type { JobApplicant } from '@factoryfixinc/ats-interfaces';
import { InternalError } from '@/core/shared/errors/internal.error';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import type { ProScore } from '@/core/conversations/application-score/application-score.type';
import { useApplicationScoreStore } from '@/core/conversations/application-score/application-score.store';
import type { ScoreReview } from '@/core/conversations/application-score/types/application-score-review.type';

export default class ApplicationScorePersistence {
  private applicationScoreStore = useApplicationScoreStore();

  public async fetchScoreDetails(
    jobApplicationId: JobApplicant['id'],
  ): Promise<ProScore | undefined> {
    const url = `/score?jobApplicationId=${jobApplicationId}`;
    const { data, error, statusCode } = await useAtsApplicationScoringApi(url)
      .get()
      .json<ProScore>();

    if (statusCode.value === HttpStatus.NOT_FOUND || !data.value) {
      return;
    }

    if (error.value) {
      throw new InternalError('Failed to fetch score details', {
        error: error.value,
        status: statusCode.value,
        data: { jobApplicationId },
      });
    }

    this.applicationScoreStore.setScoreDetail(data.value);
    return data.value;
  }

  public async sendScoreFeedback(review: ScoreReview): Promise<ScoreReview> {
    if (!review.userLiked && (!review.userRate || !review.userFeedBack)) {
      throw new InternalError('Missing score review details', {
        error: new Error('Missing score review details'),
        data: { review },
      });
    }

    const url = `/score/feedback`;
    const { data, error, statusCode } = await useAtsApplicationScoringApi(url)
      .post(review)
      .json<ScoreReview>();

    if (error.value || !data.value) {
      throw new InternalError('Failed to post score feedback', {
        error: error.value,
        status: statusCode.value,
        data: { review },
      });
    }

    return data.value;
  }
}
