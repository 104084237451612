<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useWindowSize } from '@vueuse/core';

import ApplicationStatusPicker from '@/components/Conversations/Application/ApplicationStatusPicker.vue';
import ProHeaderContactDetails from './ProHeaderContactDetails.vue';
import ProProfileService from '@/core/conversations/pro-profile/pro-profile.service';

import ApplicationScoreHoverWrapper from '../Application/ApplicationScoreHoverWrapper.vue';

import { JobApplicantStatus } from '@factoryfixinc/ats-interfaces';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import TrackingService from '@/core/shared/tracking/tracking.service';
import ApplicationScoreService from '@/core/conversations/application-score/application-score.service';
import type { ProScore } from '@/core/conversations/application-score/application-score.type';
import ConversationIndexService from '@/core/conversations/conversation-index/conversation-index.service';
import ProjectService from '@/core/shared/project/project.service';
import ProNotesService from '@/core/shared/pro-notes/pro-notes.service';
import { getJobTitle } from '@/core/sourcing/utils/job';
import StarWithoutBgIcon from '@/assets/svg/conversations/star_without_bg.svg?component';

const emit = defineEmits<{
  (e: 'change:pro-score', value: ProScore): void;
}>();

const proProfileService = new ProProfileService();
const projectService = new ProjectService();
const conversationIndexService = new ConversationIndexService();
const proNotesService = new ProNotesService();

const { width } = useWindowSize();

const projectName = ref<string>('');

const userProfile = computed(() => proProfileService.selectedProProfile);
const jobApplicant = computed(() => proProfileService.selectedProApplication);
const computedJobApplicantId = computed(() => jobApplicant.value?.id);

const windowWidth = computed(() => width.value);
const startHidingContent = computed(() => windowWidth.value < 1280);

async function loadProjectName() {
  projectName.value = getJobTitle(jobApplicant.value?.job, false).displayTitle;
}

const loadData = async () => {
  await loadProjectName();
};

async function updateApplicationStatus(
  newStatus: JobApplicantStatus,
  previousStatus: JobApplicantStatus,
  reason: string,
  isSilent: boolean,
) {
  if (!newStatus) {
    return;
  }
  const currentStatus = proProfileService.selectedProApplication?.status;
  const proId = proProfileService.selectedProProfile?.pro.id;
  if (computedJobApplicantId.value) {
    await proProfileService.changeApplicationStatus(
      computedJobApplicantId.value,
      newStatus,
      reason,
      isSilent,
    );

    if (newStatus === JobApplicantStatus.REJECTED && reason && proId) {
      await proNotesService.createProRejectedNote(proId, reason);
    }

    conversationIndexService.filterLocalConversationIndexesByStatus(
      computedJobApplicantId.value,
      newStatus,
    );

    // To avoid double counting for REJECTED:
    if (previousStatus.includes('Reject')) {
      previousStatus = JobApplicantStatus.REJECTED;
    }
    projectService.updateLocalProjectJobApplicationStatusCount({
      jobId: Number(proProfileService.selectedProApplication?.jobId),
      newStatus,
      previousStatus,
    });
  }

  TrackingService.trackAction(TrackingActionName.APPLICATION_STATUS_CHANGED, {
    source: 'Conversation Top Banner',
    from_status: currentStatus,
    to_status: newStatus,
  });
}

function getScoreBgColor(proScore: ProScore | undefined) {
  const score = proScore?.score;

  if (!score) {
    return 'bg-primary-300';
  }

  if (score >= 4) {
    return 'bg-primary-300';
  }
  if (score >= 2) {
    return 'bg-caution-300';
  }
  return 'bg-critical-400';
}

watch(computedJobApplicantId, async () => {
  await loadData();
});

onMounted(async () => {
  await loadData();
});
</script>

<template>
  <section class="p-4 pb-1">
    <div class="flex justify-between gap-3">
      <div class="flex-auto">
        <div class="relative flex w-full items-center">
          <div class="truncate font-serif text-base font-black leading-normal text-black">
            {{ userProfile?.nameFirst }}
            {{ userProfile?.nameLast }}
          </div>
          <div class="ml-3 mr-1 rounded bg-tint-40 font-sans text-sm">
            <ApplicationScoreHoverWrapper
              v-if="jobApplicant?.job?.id && jobApplicant?.id"
              :application-id="jobApplicant?.id"
              :initiated-by-outreach="jobApplicant?.initiatedByOutreach"
              :job-title="projectName"
              :job-id="jobApplicant?.job?.id"
              :fallback-score="jobApplicant?.score"
              :load-on-mount="true"
              popup-location="bottom center"
              analytics-source="Conversation Top Banner"
              @change:pro-score="emit('change:pro-score', $event)"
            >
              <template #hoverTrigger="{ scoreDetails, scoreIsLoading }">
                <div class="flex items-center rounded bg-tint-40 p-0.5 pr-1 transition-all">
                  <template v-if="scoreIsLoading">
                    <div class="flex items-center gap-2">
                      <div
                        class="flex h-[18px] w-[18px] items-center justify-center rounded border-2 border-white bg-tint-200"
                      >
                        <div
                          class="flex h-2.5 w-2.5 animate-spin rounded-full border-2 border-black border-t-tint-200"
                        ></div>
                      </div>

                      <span class="text-xs text-shade-820">Loading...</span>
                    </div>
                  </template>
                  <template v-else-if="jobApplicant.initiatedByOutreach">
                    <div class="flex h-full items-center gap-2">
                      <img
                        src="@/assets/svg/conversations/users-search-sourcing.svg"
                        alt="User"
                        class="h-4 w-4"
                      />
                      <span v-if="!startHidingContent" class="mt-0.5 text-xs text-shade-820"
                        >Not sourced by Copilot</span
                      >
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="scoreDetails && scoreDetails.score">
                      <div
                        class="cursor flex items-center space-x-0.5 rounded-sm px-1 py-0.5"
                        :class="getScoreBgColor(scoreDetails)"
                      >
                        <StarWithoutBgIcon alt="Star" class="h-3 w-3 text-shade-900" />
                        <div class="text-xs font-semibold text-shade-880">
                          {{ ApplicationScoreService.formatScore(scoreDetails.score) }}
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="flex items-center rounded-sm bg-shade-880 text-xs">N/A</div>
                    </template>
                    <template v-if="!startHidingContent">
                      <div class="ml-2 mr-1 text-xs text-shade-880">for</div>
                      <span class="text-xs font-bold text-shade-880">{{ projectName }}</span>
                    </template>
                  </template>
                </div>
              </template>
            </ApplicationScoreHoverWrapper>
          </div>
          <div class="ml-auto">
            <ApplicationStatusPicker
              v-if="jobApplicant"
              slim
              :status="jobApplicant?.status"
              :rejection-reason="jobApplicant?.rejectionReason"
              show-download-profile
              @update-value="
                ({ newStatus, previousStatus, reason, isSilent }) =>
                  updateApplicationStatus(newStatus, previousStatus, reason, isSilent)
              "
            />
          </div>
        </div>
        <ProHeaderContactDetails :window-width="windowWidth" />
      </div>
    </div>
  </section>
</template>
