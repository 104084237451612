/* eslint-disable max-lines */
import {
  HireType,
  type Job,
  type JobTaxonomyMachineMapWithRelations,
  type JobTitle,
  type JobWithRelations,
  PayRatePeriod,
  type Project,
  ScreeningAction,
} from '@factoryfixinc/ats-interfaces';

import { JobPersistence } from '@/core/shared/job/job.persistence';
import { useCopilotActivationStore } from './copilot-activation.store';
import JobTitleService from '@/core/shared/job-title/job-title.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import TaxonomyService from '@/core/shared/taxonomy/taxonomy.service';
import VersionsService from '@/core/jobs/versions/versions.service';

import type {
  GenerateJobDescriptionOptions,
  GenerateScreenerQuestionsOptions,
  GeneratedJobDescriptionDocument,
  GeneratedScreenerQuestionsDocument,
} from '@/core/shared/job/job.type';
import { EmployerFeatureService } from '@/core/shared/employer-feature/employer-feature.service';
import {
  type LocalScreenerQuestion,
  MINIMUM_REQUIRED_SCREENER_QUESTIONS,
} from './types/local-screener-question.type';
import {
  cleanHTMLJobDescription,
  makeMarkdownVersionOfJobDescription,
} from '@/core/jobs/copilot-activation/utils/html-parsing.utils';
import type { LocalJobData } from './local-job-data.type';

export default class CopilotActivationService {
  private readonly jobTitleService = new JobTitleService();
  private readonly jobPersistence = new JobPersistence();
  private readonly employerFeatureService = new EmployerFeatureService();
  private readonly taxonomyService = new TaxonomyService();
  private readonly copilotActivationStore = useCopilotActivationStore();
  private readonly jobVersionsService = new VersionsService();

  public get selectedProjectId(): number | null {
    return this.copilotActivationStore.selectedProjectId;
  }

  public get selectedJobId(): number | null {
    return this.copilotActivationStore.selectedJobId;
  }

  // ====== START: DATA MAPPING ======
  public get originalJobTitle(): string | null {
    return this.copilotActivationStore.selectedJob?.displayTitle ?? null;
  }

  public get originalHireType(): HireType | null {
    return this.copilotActivationStore.selectedJob?.hireType ?? null;
  }

  public get originalShift(): number | null {
    return this.copilotActivationStore.selectedJob?.shift ?? null;
  }

  public get jobStatus(): Job['status'] | null {
    return this.copilotActivationStore.jobStatus;
  }

  public set jobStatus(value: Job['status'] | null) {
    this.copilotActivationStore.jobStatus = value;
  }

  // Validations
  public get hasTriedToUpdateJob(): boolean {
    return this.copilotActivationStore.hasTriedToUpdateJob;
  }

  public set hasTriedToUpdateJob(value: boolean) {
    this.copilotActivationStore.hasTriedToUpdateJob = value;
  }

  public get localJobData(): LocalJobData {
    return this.copilotActivationStore.localJobData;
  }

  public get localJobDataInformation(): { [key: string]: string } {
    return {
      jobData: JSON.stringify(this.copilotActivationStore.localJobData),
      knowledgeDisciplines: JSON.stringify(this.knowledgeDisciplines),
      machines: JSON.stringify(this.machines),
      brandsPerMachine: JSON.stringify(this.orderedBrandsPerMachine),
      screenerQuestions: JSON.stringify(this.screenerQuestions),
      screeningAction: JSON.stringify(this.screeningAction),
    };
  }

  // DISPLAY TITLE
  public get displayTitle(): string | null {
    return this.copilotActivationStore.localJobData.displayTitle;
  }
  public set displayTitle(value: string | null) {
    this.copilotActivationStore.localJobData.displayTitle = value;
  }

  // JOB TITLE
  public get jobTitleId(): number | null {
    return this.copilotActivationStore.localJobData.jobTitleId;
  }

  public set jobTitleId(value: number | null) {
    this.copilotActivationStore.localJobData.jobTitleId = value;
  }

  // HIRE TYPE
  public get hireType(): HireType | null {
    return this.copilotActivationStore.localJobData.hireType;
  }
  public set hireType(value: HireType | null) {
    const oldHireTypeValue = this.copilotActivationStore.localJobData.hireType;
    this.copilotActivationStore.localJobData.hireType = value;

    if (value === HireType.PROJECT) {
      // Set shift = 4 as that portrays the PROJECT hireType
      this.copilotActivationStore.localJobData.shift = 4;
      // startTime and endTime should be null for PROJECT hireType
      this.copilotActivationStore.localJobData.startTime = null;
      this.copilotActivationStore.localJobData.endTime = null;
    } else if (oldHireTypeValue === HireType.PROJECT) {
      // Reset shift to null, do not show the user the PROJECT shift
      this.copilotActivationStore.localJobData.shift = null;
    }
  }

  // Shift
  public get shift(): number | null {
    return this.copilotActivationStore.localJobData.shift;
  }
  public set shift(value: number | null) {
    this.copilotActivationStore.localJobData.shift = value;

    // For all the shifts other than 0 (Other), set startTime and endTime to null
    if (value !== 0) {
      this.copilotActivationStore.localJobData.startTime = null;
      this.copilotActivationStore.localJobData.endTime = null;
    }
  }

  // Shift start/end time
  public get startTime(): string | null {
    return this.copilotActivationStore.localJobData.startTime;
  }
  public set startTime(value: string | null) {
    this.copilotActivationStore.localJobData.startTime = value;
  }
  public get endTime(): string | null {
    return this.copilotActivationStore.localJobData.endTime;
  }
  public set endTime(value: string | null) {
    this.copilotActivationStore.localJobData.endTime = value;
  }

  // Pay rate min/max
  public get payRateMin(): number | null {
    return this.copilotActivationStore.localJobData.payRateMin;
  }
  public set payRateMin(value: number | null) {
    this.copilotActivationStore.localJobData.payRateMin = value;
  }
  public get payRateMax(): number | null {
    return this.copilotActivationStore.localJobData.payRateMax;
  }
  public set payRateMax(value: number | null) {
    this.copilotActivationStore.localJobData.payRateMax = value;
  }
  public get payRateSalaryMin(): number | null {
    return this.copilotActivationStore.localJobData.payRateSalaryMin;
  }
  public set payRateSalaryMin(value: number | null) {
    this.copilotActivationStore.localJobData.payRateSalaryMin = value;
  }
  public get payRateSalaryMax(): number | null {
    return this.copilotActivationStore.localJobData.payRateSalaryMax;
  }
  public set payRateSalaryMax(value: number | null) {
    this.copilotActivationStore.localJobData.payRateSalaryMax = value;
  }
  public get payRatePeriod(): PayRatePeriod {
    return this.copilotActivationStore.localJobData.payRatePeriod;
  }
  public set payRatePeriod(value: PayRatePeriod) {
    this.copilotActivationStore.localJobData.payRatePeriod = value;
  }

  // Location
  public get street1(): string | null {
    return this.copilotActivationStore.localJobData.street1;
  }

  public set street1(value: string) {
    this.copilotActivationStore.localJobData.street1 = value;
  }

  public get city(): string | null {
    return this.copilotActivationStore.localJobData.city;
  }

  public set city(value: string) {
    this.copilotActivationStore.localJobData.city = value;
  }

  public get state(): string | null {
    return this.copilotActivationStore.localJobData.state;
  }

  public set state(value: string) {
    this.copilotActivationStore.localJobData.state = value;
  }

  public get postalCode(): string | null {
    return this.copilotActivationStore.localJobData.postalCode;
  }

  public set postalCode(value: string) {
    this.copilotActivationStore.localJobData.postalCode = value;
  }

  public get anonymous(): boolean {
    return this.copilotActivationStore.localJobData.anonymous;
  }

  public set anonymous(value: boolean) {
    this.copilotActivationStore.localJobData.anonymous = value;
  }

  // Knowledge disciplines
  public get knowledgeDisciplines(): number[] {
    return this.copilotActivationStore.localKnowledgeDisciplines.sort();
  }

  public set knowledgeDisciplines(value: number[]) {
    this.copilotActivationStore.localKnowledgeDisciplines = value;
  }

  // Machines & Brands
  public get machines(): number[] {
    return this.copilotActivationStore.localMachines.sort();
  }

  public set machines(value: number[]) {
    this.copilotActivationStore.localMachines = value;
  }

  public get brandsPerMachine(): { [key: number]: number[] } {
    return this.copilotActivationStore.localBrandsPerMachine;
  }
  // This sort will help us know if there are changes in the brands
  // to do a Form isDirty check
  public get orderedBrandsPerMachine(): { [key: number]: number[] } {
    const result: { [key: number]: number[] } = {};
    for (const machineId in this.copilotActivationStore.localBrandsPerMachine) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.copilotActivationStore.localBrandsPerMachine,
          machineId,
        )
      ) {
        const brands = this.copilotActivationStore.localBrandsPerMachine[machineId];
        result[machineId] = brands.slice().sort((a, b) => a - b); // Create a copy and sort
      }
    }

    return result;
  }

  public set brandsPerMachine(value: { [key: number]: number[] }) {
    this.copilotActivationStore.localBrandsPerMachine = value;
  }

  // Candidate Screening
  public get screeningAction(): ScreeningAction | null {
    return this.copilotActivationStore.localScreeningAction;
  }

  public set screeningAction(value: ScreeningAction | null) {
    this.copilotActivationStore.localScreeningAction = value;
  }

  public get employerCareerSiteUrl(): string | null {
    return this.copilotActivationStore.localEmployerCareerSiteUrl;
  }

  public set employerCareerSiteUrl(value: string | null) {
    this.copilotActivationStore.localEmployerCareerSiteUrl = value;
  }

  // Screener Questions
  public get screenerQuestions(): LocalScreenerQuestion[] {
    return this.copilotActivationStore.localScreenerQuestions;
  }

  public set screenerQuestions(value: LocalScreenerQuestion[]) {
    this.copilotActivationStore.localScreenerQuestions = value;
  }

  // Job Description
  public get description(): string | null {
    return this.copilotActivationStore.localJobData.description;
  }

  public set description(value: string | null) {
    this.copilotActivationStore.localJobData.description = value;
  }

  public get rawDescription(): string | null {
    return this.copilotActivationStore.localJobData.rawDescription;
  }

  public set rawDescription(value: string | null) {
    this.copilotActivationStore.localJobData.rawDescription = value;
  }

  public get localJobDescription(): string {
    return this.copilotActivationStore.localJobDescription ?? '';
  }

  public set localJobDescription(value: string) {
    this.copilotActivationStore.localJobDescription = value;
  }

  // Job Description Generation
  public get isGeneratingJobDescription(): boolean {
    return this.copilotActivationStore.isGeneratingJobDescription;
  }

  public get generatedJobDescriptionDocument(): GeneratedJobDescriptionDocument | null {
    return this.copilotActivationStore.generatedJobDescriptionDocument;
  }

  public set stopGeneratingJobDescriptionFlag(value: boolean) {
    this.copilotActivationStore.stopGeneratingJobDescriptionFlag = value;
  }

  // Screener Questions Generation
  public get isGeneratingScreenerQuestions(): boolean {
    return this.copilotActivationStore.isGeneratingScreenerQuestions;
  }

  public get generatedScreenerQuestionsDocument(): GeneratedScreenerQuestionsDocument | null {
    return this.copilotActivationStore.generatedScreenerQuestionsDocument;
  }

  public set stopGeneratingScreenerQuestionsFlag(value: boolean) {
    this.copilotActivationStore.stopGeneratingScreenerQuestionsFlag = value;
  }

  // ====== END: DATA MAPPING ======

  async setupStoreByProject(
    project: Project,
    useJobVersion = false,
  ): Promise<JobWithRelations<'jobStatusHistories'> | undefined> {
    const projectId = project.id;
    const jobId = project.jobId;
    let job;
    const isJobVersion = useJobVersion && !!this.jobVersionsService.jobVersionWithRelations;

    if (isJobVersion) {
      job = this.jobVersionsService.jobVersionWithRelations;
    } else {
      job = await this.jobPersistence.getByIdWithRelations(jobId, [
        'jobTitle',
        'screenerQuestions',
        'jobQualifications',
        'jobResponsibilities',
        'jobTaxonomyMachineMaps',
        'jobTaxonomyKnowledgeDisciplineMaps',
        'jobStatusHistories',
      ]);
    }

    if (!job) {
      this.copilotActivationStore.selectedProjectId = null;
      this.copilotActivationStore.selectedJobId = null;
      this.copilotActivationStore.selectedJob = null;
      this.resetStoreData();
    } else {
      this.resetStoreData(isJobVersion);

      // Fetch needed data for the job
      this.copilotActivationStore.jobTitles = await this.jobTitleService.fetchJobTitles();

      this.copilotActivationStore.selectedProjectId = projectId;
      this.copilotActivationStore.selectedJobId = jobId;
      this.copilotActivationStore.selectedJob = job;

      this.mapJobDetailsData(job);

      if (this.copilotActivationStore.localJobData.displayTitle) {
        const jobTitle = await this.fetchRelatedJobTitleForDisplayTitle(
          this.copilotActivationStore.localJobData.displayTitle,
        );
        this.copilotActivationStore.localJobData.jobTitleId = jobTitle?.id ?? null;
        this.copilotActivationStore.selectedJobTitle = jobTitle;
      }

      this.mapSkillkeywordsData(job);

      // Should show candidate screening or screener questions
      const isDoingCandidateScreening = this.employerFeatureService.hasUrlScreeningEnabled;
      this.mapCandidateScreeningData(job, isDoingCandidateScreening);

      this.mapScreeningQuestionsData(job);

      this.mapJobDescriptionData(job);
    }

    return job;
  }

  private resetStoreData(isJobVersion = false): void {
    if (!isJobVersion) {
      this.copilotActivationStore.hasTriedToUpdateJob = false;
      this.copilotActivationStore.selectedProjectId = null;
      this.copilotActivationStore.selectedJobId = null;
      this.copilotActivationStore.selectedJob = null;
      this.copilotActivationStore.selectedJobTitle = null;
      this.copilotActivationStore.jobDescriptionDocumentId = '';
      this.copilotActivationStore.generatedJobDescriptionDocument = null;
      this.copilotActivationStore.jobDescriptionPollingIntervalId = null;
      this.copilotActivationStore.isGeneratingJobDescription = false;
      this.copilotActivationStore.stopGeneratingJobDescriptionFlag = false;
      this.copilotActivationStore.generatedScreenerQuestionsDocumentId = '';
      this.copilotActivationStore.generatedScreenerQuestionsDocument = null;
      this.copilotActivationStore.screenerQuestionsPollingIntervalId = null;
      this.copilotActivationStore.isGeneratingScreenerQuestions = false;
      this.copilotActivationStore.stopGeneratingScreenerQuestionsFlag = false;
      this.copilotActivationStore.jobStatus = null;

      this.copilotActivationStore.localJobData = {
        employerName: '',
        jobTitleId: null,
        displayTitle: null,
        employerAssigneeId: null,
        hireType: null,
        shift: null,
        startTime: null,
        endTime: null,
        description: '',
        rawDescription: '',
        payRateMin: null,
        payRateMax: null,
        payRateSalaryMin: null,
        payRateSalaryMax: null,
        payRatePeriod: PayRatePeriod.HOURLY,
        minimumApplicantsPerWeek: 0,
        hiresPerPosition: 0,
        street1: '',
        city: '',
        state: '',
        postalCode: '',
        anonymous: false,
      };
    }
    this.copilotActivationStore.localJobDescription = '';
    this.copilotActivationStore.localKnowledgeDisciplines = [];
    this.copilotActivationStore.localMachines = [];
    this.copilotActivationStore.localBrandsPerMachine = {};
    this.copilotActivationStore.localScreeningAction = null;
    this.copilotActivationStore.localEmployerCareerSiteUrl = null;
    this.copilotActivationStore.localScreenerQuestions = [];
    this.copilotActivationStore.jobTitles = [];
  }

  private mapJobDetailsData(job: Job): void {
    this.copilotActivationStore.jobStatus = job.status ?? null;
    this.copilotActivationStore.localJobData.displayTitle = job.displayTitle ?? '';
    this.copilotActivationStore.localJobData.hireType = job.hireType ?? null;
    this.copilotActivationStore.localJobData.shift = job.shift ?? null;
    this.copilotActivationStore.localJobData.anonymous = job.anonymous ?? false;
    if (job.hireType === HireType.PROJECT) {
      this.copilotActivationStore.localJobData.shift = 4;
    }

    if (job.shift === 0) {
      this.copilotActivationStore.localJobData.startTime = job.startTime ?? null;
      this.copilotActivationStore.localJobData.endTime = job.endTime ?? null;
    } else {
      this.copilotActivationStore.localJobData.startTime = null;
      this.copilotActivationStore.localJobData.endTime = null;
    }

    this.copilotActivationStore.localJobData.payRateMin = job.payRateMin ?? null;
    this.copilotActivationStore.localJobData.payRateMax = job.payRateMax ?? null;
    this.copilotActivationStore.localJobData.payRateSalaryMin = job.payRateSalaryMin ?? null;
    this.copilotActivationStore.localJobData.payRateSalaryMax = job.payRateSalaryMax ?? null;
    this.copilotActivationStore.localJobData.payRatePeriod =
      job.payRatePeriod ?? PayRatePeriod.HOURLY;

    this.copilotActivationStore.localJobData.street1 = job.street1 ?? '';
    this.copilotActivationStore.localJobData.city = job.city ?? '';
    this.copilotActivationStore.localJobData.state = job.state ?? '';
    this.copilotActivationStore.localJobData.postalCode = job.postalCode ?? '';
  }

  private mapSkillkeywordsData(
    job: JobWithRelations<
      | 'jobTitle'
      | 'screenerQuestions'
      | 'jobQualifications'
      | 'jobResponsibilities'
      | 'jobTaxonomyKnowledgeDisciplineMaps'
      | 'jobTaxonomyMachineMaps'
    >,
  ): void {
    if (job.jobTaxonomyKnowledgeDisciplineMaps) {
      this.copilotActivationStore.localKnowledgeDisciplines =
        job.jobTaxonomyKnowledgeDisciplineMaps.map((knowledgeDiscipline) => {
          return knowledgeDiscipline.taxonomyKnowledgeDisciplineId;
        });
    }

    if (job.jobTaxonomyMachineMaps) {
      for (const machine of job.jobTaxonomyMachineMaps) {
        this.copilotActivationStore.localMachines.push(machine.taxonomyMachineId);
        this.copilotActivationStore.localBrandsPerMachine[machine.taxonomyMachineId] = [];

        if (
          Object.prototype.hasOwnProperty.call(machine, 'jobTaxonomyMachineMapTaxonomyBrandMaps')
        ) {
          for (const brand of (
            machine as JobTaxonomyMachineMapWithRelations<'jobTaxonomyMachineMapTaxonomyBrandMaps'>
          ).jobTaxonomyMachineMapTaxonomyBrandMaps) {
            this.copilotActivationStore.localBrandsPerMachine[machine.taxonomyMachineId].push(
              brand.taxonomyBrandId,
            );
          }
        }
      }
    }
  }

  private mapCandidateScreeningData(job: Job, isDoingCandidateScreening: boolean): void {
    if (!isDoingCandidateScreening) {
      this.copilotActivationStore.localScreeningAction = null;
      this.copilotActivationStore.localEmployerCareerSiteUrl = null;
    } else {
      this.copilotActivationStore.localScreeningAction = job.screeningAction ?? null;
      if (job.screeningAction === ScreeningAction.URL) {
        this.copilotActivationStore.localEmployerCareerSiteUrl = job.employerCareerSiteUrl ?? '';
      } else if (job.screeningAction === ScreeningAction.SCREEN) {
        this.copilotActivationStore.localEmployerCareerSiteUrl = null;
      }
    }
  }

  private mapScreeningQuestionsData(job: JobWithRelations<'screenerQuestions'>): void {
    let questionId = 1;
    if (job.screenerQuestions) {
      for (const question of job.screenerQuestions) {
        this.copilotActivationStore.localScreenerQuestions.push({
          text: question.question,
          id: questionId,
        });
        questionId += 1;
      }
    }

    // Have at least 3 questions in the UI
    if (
      this.copilotActivationStore.localScreenerQuestions.length <
      MINIMUM_REQUIRED_SCREENER_QUESTIONS
    ) {
      const difference =
        MINIMUM_REQUIRED_SCREENER_QUESTIONS -
        this.copilotActivationStore.localScreenerQuestions.length;

      for (let i = 0; i < difference; i++) {
        this.copilotActivationStore.localScreenerQuestions.push({
          text: '',
          id: questionId,
        });
        questionId += 1;
      }
    }
  }

  private mapJobDescriptionData(job: Job): void {
    /**
     * Parse locally the HTML description into markdown so we can
     * work with the raw md on the flow. Also handles jobs that do have
     * an HTML description, but no raw description stored on the db.
     */
    const cleanedDescription = cleanHTMLJobDescription(job.description ?? '');
    const rawDescription = makeMarkdownVersionOfJobDescription(cleanedDescription);

    this.copilotActivationStore.localJobData.description = cleanedDescription;
    this.copilotActivationStore.localJobData.rawDescription = rawDescription;

    this.copilotActivationStore.localJobDescription = cleanedDescription;
  }

  public async fetchRelatedJobTitleForDisplayTitle(displayTitle: string): Promise<JobTitle | null> {
    return this.jobTitleService.normalizeJobTitle(displayTitle);
  }

  // ====== START: JOB DESCRIPTION GENERATION ======
  public async generateJobDescription(): Promise<string | undefined> {
    // Reset polling
    this.clearJobDescriptionGenerationPolling();
    this.copilotActivationStore.generatedJobDescriptionDocument = null;
    this.copilotActivationStore.isGeneratingJobDescription = true;

    const title = this.copilotActivationStore.localJobData.displayTitle ?? '';
    const city = this.copilotActivationStore.localJobData.city ?? '';
    const state = this.copilotActivationStore.localJobData.state ?? '';
    const postalCode = this.copilotActivationStore.localJobData.postalCode ?? '';
    const location = `${city}, ${state}, ${postalCode}`;
    const skills: string[] = [];
    const brands: string[] = [];

    for (const skillId of this.copilotActivationStore.localKnowledgeDisciplines) {
      const skill = this.taxonomyService.knowledgeDisciplines.find((knowledgeDiscipline) => {
        return knowledgeDiscipline.id === skillId;
      });
      if (skill) {
        skills.push(skill.title);
      }
    }

    for (const machineId of this.copilotActivationStore.localMachines) {
      const skill = this.taxonomyService.machines.find((machine) => {
        return machine.id === machineId;
      });
      if (skill) {
        skills.push(skill.title);

        const brandsForMachine = this.copilotActivationStore.localBrandsPerMachine[machineId];
        for (const brandId of brandsForMachine) {
          const brand = this.taxonomyService.brands.find((brand) => {
            return brand.id === brandId;
          });
          if (brand) {
            brands.push(brand.title);
          }
        }
      }
    }

    const options: GenerateJobDescriptionOptions = {
      title: title,
      location: location,
      skills,
      brands,
    };

    const generatedDescriptionDocumentId =
      await this.jobPersistence.generateJobDescription(options);

    if (generatedDescriptionDocumentId) {
      // Start polling
      this.copilotActivationStore.jobDescriptionDocumentId = generatedDescriptionDocumentId;
      this.startPollingJobDescriptionGeneration(generatedDescriptionDocumentId);
    } else {
      this.copilotActivationStore.jobDescriptionDocumentId = '';
      this.copilotActivationStore.isGeneratingJobDescription = false;
    }

    return generatedDescriptionDocumentId;
  }

  public clearJobDescriptionGenerationPolling(): void {
    if (this.copilotActivationStore.jobDescriptionPollingIntervalId) {
      window?.clearInterval(this.copilotActivationStore.jobDescriptionPollingIntervalId);
    }

    this.copilotActivationStore.jobDescriptionPollingIntervalId = null;
    this.copilotActivationStore.jobDescriptionPollingTriedAttempts = 0;
    this.copilotActivationStore.isGeneratingJobDescription = false;
  }

  public async startPollingJobDescriptionGeneration(documentId: string): Promise<void> {
    this.copilotActivationStore.jobDescriptionPollingIntervalId = window?.setInterval(async () => {
      const generatedDescriptionDocument =
        this.copilotActivationStore.generatedJobDescriptionDocument;

      if (generatedDescriptionDocument?.jobDescription.fullfilled) {
        // Description already generated, parse it and stop polling
        SnackbarService.success('Job description generated successfully');
        this.clearJobDescriptionGenerationPolling();
      } else {
        // Description not generated yet, keep polling
        const pollingAttempts = this.copilotActivationStore.jobDescriptionPollingTriedAttempts;
        const maxAttemps = this.copilotActivationStore.jobDescriptionPollingMaxAttempts;
        if (pollingAttempts > maxAttemps) {
          this.clearJobDescriptionGenerationPolling();
          this.copilotActivationStore.jobDescriptionDocumentId = '';
          SnackbarService.critical(
            'There was a problem generating the job description, try again later.',
          );
        } else if (this.copilotActivationStore.stopGeneratingJobDescriptionFlag) {
          this.clearJobDescriptionGenerationPolling();
          this.copilotActivationStore.jobDescriptionDocumentId = '';
          this.copilotActivationStore.stopGeneratingJobDescriptionFlag = false;
          SnackbarService.caution('Job description generation was stopped.');
        } else {
          this.copilotActivationStore.generatedJobDescriptionDocument =
            await this.jobPersistence.getGeneratedJobDescription(documentId);
          this.copilotActivationStore.jobDescriptionPollingTriedAttempts += 1;
        }
      }
    }, this.copilotActivationStore.jobDescriptionPollingIntervalTime);
  }

  public stopGeneratingJobDescription(): void {
    this.copilotActivationStore.stopGeneratingJobDescriptionFlag = true;
  }
  // ====== END: JOB DESCRIPTION GENERATION ======

  // ====== START: SCREENING QUESTIONS GENERATION ======
  public async generateScreenerQuestions(): Promise<string | undefined> {
    // Reset polling
    this.clearScreenerQuestionsGenerationPolling();
    this.copilotActivationStore.isGeneratingScreenerQuestions = true;
    this.copilotActivationStore.generatedScreenerQuestionsDocument = null;

    const title = this.copilotActivationStore.localJobData.displayTitle ?? '';
    const skills: string[] = [];
    const brands: string[] = [];

    for (const skillId of this.copilotActivationStore.localKnowledgeDisciplines) {
      const skill = this.taxonomyService.knowledgeDisciplines.find((knowledgeDiscipline) => {
        return knowledgeDiscipline.id === skillId;
      });
      if (skill) {
        skills.push(skill.title);
      }
    }

    for (const machineId of this.copilotActivationStore.localMachines) {
      const skill = this.taxonomyService.machines.find((machine) => {
        return machine.id === machineId;
      });
      if (skill) {
        skills.push(skill.title);

        const brandsForMachine = this.copilotActivationStore.localBrandsPerMachine[machineId];
        for (const brandId of brandsForMachine) {
          const brand = this.taxonomyService.brands.find((brand) => {
            return brand.id === brandId;
          });
          if (brand) {
            brands.push(brand.title);
          }
        }
      }
    }

    const options: GenerateScreenerQuestionsOptions = {
      title,
      skills,
      brands,
    };

    const generatedDescriptionDocumentId =
      await this.jobPersistence.generateScreenerQuestions(options);

    if (generatedDescriptionDocumentId) {
      // Start polling
      this.copilotActivationStore.generatedScreenerQuestionsDocumentId =
        generatedDescriptionDocumentId;

      this.startPollingScreenerQuestionsGeneration(generatedDescriptionDocumentId);
    } else {
      this.copilotActivationStore.generatedScreenerQuestionsDocumentId = '';
      this.copilotActivationStore.isGeneratingScreenerQuestions = false;
    }

    return generatedDescriptionDocumentId;
  }

  public clearScreenerQuestionsGenerationPolling(): void {
    if (this.copilotActivationStore.screenerQuestionsPollingIntervalId) {
      window?.clearInterval(this.copilotActivationStore.screenerQuestionsPollingIntervalId);
    }

    this.copilotActivationStore.screenerQuestionsPollingIntervalId = null;
    this.copilotActivationStore.screenerQuestionsPollingTriedAttempts = 0;
    this.copilotActivationStore.isGeneratingScreenerQuestions = false;
  }

  public async startPollingScreenerQuestionsGeneration(documentId: string): Promise<void> {
    this.copilotActivationStore.screenerQuestionsPollingIntervalId = window?.setInterval(
      async () => {
        const generatedDescriptionDocument =
          this.copilotActivationStore.generatedScreenerQuestionsDocument;

        if (generatedDescriptionDocument?.screeningQuestions.fullfilled) {
          // Description already generated, parse it and stop polling
          SnackbarService.success('Screener questions generated successfully');
          this.clearScreenerQuestionsGenerationPolling();
        } else {
          // Screener questions not generated yet, keep polling
          const pollingAttempts = this.copilotActivationStore.screenerQuestionsPollingTriedAttempts;
          const maxAttemps = this.copilotActivationStore.screenerQuestionsPollingMaxAttempts;
          if (pollingAttempts > maxAttemps) {
            this.clearScreenerQuestionsGenerationPolling();
            this.copilotActivationStore.generatedScreenerQuestionsDocumentId = '';
            SnackbarService.critical(
              'There was a problem generating the screener questions, try again later.',
            );
          } else if (this.copilotActivationStore.stopGeneratingScreenerQuestionsFlag) {
            this.clearScreenerQuestionsGenerationPolling();
            this.copilotActivationStore.generatedScreenerQuestionsDocumentId = '';
            this.copilotActivationStore.stopGeneratingScreenerQuestionsFlag = false;
            SnackbarService.caution('Screener questions generation was stopped.');
          } else {
            this.copilotActivationStore.generatedScreenerQuestionsDocument =
              await this.jobPersistence.getGeneratedScreenerQuestions(documentId);
            this.copilotActivationStore.screenerQuestionsPollingTriedAttempts += 1;
          }
        }
      },
      this.copilotActivationStore.screenerQuestionsPollingIntervalTime,
    );
  }

  public stopGeneratingScreenerQuestions(): void {
    this.copilotActivationStore.stopGeneratingScreenerQuestionsFlag = true;
  }
  // ====== END: SCREENING QUESTIONS GENERATION ======

  // ====== START: JOB UPDATE ======
  public async updateJob(): Promise<void> {
    if (!this.copilotActivationStore.selectedJobId) {
      return;
    }

    const jobId = this.copilotActivationStore.selectedJobId;

    let usingJobTitleId = this.copilotActivationStore.localJobData.jobTitleId;
    if (this.copilotActivationStore.localJobData.displayTitle) {
      try {
        const jobTitle = await this.fetchRelatedJobTitleForDisplayTitle(
          this.copilotActivationStore.localJobData.displayTitle,
        );

        if (jobTitle) {
          usingJobTitleId = jobTitle.id;
        }
      } catch (error) {
        // Fail silently
      }
    }

    // Get skills map objects
    const knowledgeDisciplines: string[] = [];
    const machinesBrands: { title: string; brands: string[] }[] = [];

    for (const skillId of this.copilotActivationStore.localKnowledgeDisciplines) {
      const skill = this.taxonomyService.knowledgeDisciplines.find((knowledgeDiscipline) => {
        return knowledgeDiscipline.id === skillId;
      });
      if (skill) {
        knowledgeDisciplines.push(skill.title);
      }
    }

    for (const machineId of this.copilotActivationStore.localMachines) {
      const machine = this.taxonomyService.machines.find((machine) => {
        return machine.id === machineId;
      });
      if (machine) {
        const machineBrands: { title: string; brands: string[] } = {
          title: machine.title,
          brands: [],
        };

        const brandsForMachine = this.copilotActivationStore.localBrandsPerMachine[machineId];
        for (const brandId of brandsForMachine) {
          const brand = this.taxonomyService.brands.find((brand) => {
            return brand.id === brandId;
          });
          if (brand) {
            machineBrands.brands.push(brand.title);
          }
        }

        machinesBrands.push(machineBrands);
      }
    }

    // Shift option "Multiple shifts" is set a value of -1, but is stored as null in the db
    let usingShift = this.copilotActivationStore.localJobData.shift;
    if (usingShift === -1) {
      usingShift = null;
    }

    await this.jobPersistence.updateJob(jobId, {
      knowledgeDisciplines: this.copilotActivationStore.localKnowledgeDisciplines,
      machineBrands: this.copilotActivationStore.localBrandsPerMachine,
      candidateScreening: {
        screeningAction: this.copilotActivationStore.localScreeningAction,
        employerCareerSiteUrl: this.copilotActivationStore.localEmployerCareerSiteUrl,
      },
      screenerQuestions: this.copilotActivationStore.localScreenerQuestions.map(
        (question) => question.text,
      ),
      jobDetails: {
        jobTitleId: usingJobTitleId,
        street1: this.copilotActivationStore.localJobData.street1,
        city: this.copilotActivationStore.localJobData.city,
        state: this.copilotActivationStore.localJobData.state,
        postalCode: this.copilotActivationStore.localJobData.postalCode,
        displayTitle: this.copilotActivationStore.localJobData.displayTitle,
        hireType: this.copilotActivationStore.localJobData.hireType,
        payRateMin: this.copilotActivationStore.localJobData.payRateMin,
        payRateMax: this.copilotActivationStore.localJobData.payRateMax,
        payRateSalaryMin: this.copilotActivationStore.localJobData.payRateSalaryMin,
        payRateSalaryMax: this.copilotActivationStore.localJobData.payRateSalaryMax,
        payRatePeriod: this.copilotActivationStore.localJobData.payRatePeriod,
        shift: usingShift,
        startTime: this.copilotActivationStore.localJobData.startTime,
        endTime: this.copilotActivationStore.localJobData.endTime,
        anonymous: this.copilotActivationStore.localJobData.anonymous,
        description: this.copilotActivationStore.localJobData.description,
        rawDescription: this.copilotActivationStore.localJobData.rawDescription,
        knowledgeDisciplines,
        machinesBrands,
      },
    });
  }
  // ====== END: JOB UPDATE ======
}
