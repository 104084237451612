<script setup lang="ts">
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';
import { sleep } from 'radash';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';
import ProjectService from '@/core/shared/project/project.service';
import { EmployerFeatureService } from '@/core/shared/employer-feature/employer-feature.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import SubscriptionService from '@/core/shared/subscription/subscription.service';

import JobHeaders from '@/components/Jobs/JobHeaders/JobHeaders.vue';
import JobDetailsForm from '@/components/Jobs/CopilotActivation/JobDetailsForm.vue';
import JobDetailsFormSkeleton from '@/components/Jobs/CopilotActivation/JobDetailsFormSkeleton.vue';
import JobSkillsForm from '@/components/Jobs/CopilotActivation/JobSkillsForm.vue';
import JobDescriptionForm from '@/components/Jobs/CopilotActivation/JobDescriptionForm.vue';
import CandidateScreeningForm from '@/components/Jobs/CopilotActivation/CandidateScreeningForm.vue';
import ScreenerQuestionsForm from '@/components/Jobs/CopilotActivation/ScreenerQuestionsForm.vue';
import ExitCopilotActivationModal from '@/components/Jobs/CopilotActivation/ExitCopilotActivationModal.vue';
import VersionHistoryList from '@/components/Jobs/CopilotActivation/VersionHistoryList.vue';
import {
  MAXIMUN_ALLOWED_SCREENER_QUESTIONS,
  MINIMUM_REQUIRED_SCREENER_QUESTIONS,
} from '@/core/jobs/copilot-activation/types/local-screener-question.type';
import { JobStatus, type Project, ScreeningAction } from '@factoryfixinc/ats-interfaces';
import type { VForm } from 'vuetify/components';
import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import AlertTriangleIcon from '@/assets/svg/jobs/alert-triangle.svg?component';
import VersionsService from '@/core/jobs/versions/versions.service';
import { JobVersionViewStatus } from '@/core/jobs/versions/types/job-version-history.type';
import type { JobStatusHistory } from '@factoryfixinc/ats-interfaces/dist/types/job-status-history.model';

const copilotActivationService = new CopilotActivationService();
const projectService = new ProjectService();
const employerFeatureService = new EmployerFeatureService();
const subscriptionService = new SubscriptionService();
const jobVersionsService = new VersionsService();

const display = useDisplay();
const route = useRoute();
const router = useRouter();

const isHeadersDrawerOpen = ref(true);
const isValidForm = ref(false);
const copilotForm = ref<VForm | null>(null);
const isExitModalOpen = ref(false);
const isLoading = ref(false);
const project = ref<Project>();
const canEditForm = ref(false);

const loadedRequirements = reactive({
  jobInformation: false,
  jobDetailsForm: true,
  jobSkillsForm: true,
  jobDescriptionForm: false,
});

const isDesktop = computed(() => display.mdAndUp.value);
const isFormReady = computed(() => Object.values(loadedRequirements).every(Boolean));

const isDoingCandidateScreening = computed(() => employerFeatureService.hasUrlScreeningEnabled);
const isDoingZeroScreening = computed(() => employerFeatureService.hasZeroScreeningEnabled);

const selectedAction = computed<ScreeningAction | null>(
  () => copilotActivationService.screeningAction || ScreeningAction.SCREEN,
);

const isDoingScreenerQuestions = computed(() => {
  return (
    !isDoingZeroScreening.value &&
    (!isDoingCandidateScreening.value ||
      (isDoingCandidateScreening.value && selectedAction.value === ScreeningAction.SCREEN))
  );
});

const projectId = computed(() => {
  const id = Number(route.params.id);

  if (isNaN(id)) {
    return undefined;
  }

  return id;
});

const hasAlreadyCopilotActivated = computed(() => {
  return copilotActivationService.jobStatus === JobStatus.LIVE;
});

const updateBtnText = computed(() => {
  if (isJobVersionEditMode.value || isJobVersionViewMode.value) {
    return 'Save';
  } else if (hasAlreadyCopilotActivated.value) {
    return 'Update Job';
  }
  return 'Activate Copilot';
});

const hasUsedAllJobSlots = computed(() => {
  return subscriptionService.usedJobSlots >= subscriptionService.jobSlots;
});

const isShowingAdditionalChargeNotice = computed(() => {
  return hasUsedAllJobSlots.value && !hasAlreadyCopilotActivated.value;
});

async function loadProjectInformation() {
  try {
    // Project id is invalid, redirect to conversations.
    if (!projectId.value) {
      await redirectToConversations();
      return;
    }

    project.value = await projectService.getProjectById(projectId.value);

    await loadJobInformation(project.value);
    loadedRequirements.jobInformation = true;
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to load job. Please try again later.');
    redirectToConversations();
  }
}

async function loadJobInformation(project: Project) {
  try {
    const job = await copilotActivationService.setupStoreByProject(project);
    if (job) {
      jobStatusHistory.value = job.jobStatusHistories ?? [];
    }
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to load job. Please try again later.');
  }
}

async function redirectToConversations() {
  return router.push('/conversations');
}

function openExitModal() {
  isExitModalOpen.value = true;
}

async function exitCopilotFlow() {
  if (window.history.length > 1) {
    router.back();
    return;
  }

  await router.push(`/sourcing/job/${projectId.value}`);
}

async function updateJob({ activate }: { activate: boolean }) {
  //Should not take any action IF the form data has not changed
  if (isSubmitDisabled.value) return;

  try {
    isLoading.value = true;
    copilotActivationService.hasTriedToUpdateJob = true;

    if (copilotForm.value) {
      await copilotForm.value.validate();
    }

    // Double check screener questions as those handle validation independently.
    const isCheckingScreenerQuestions = isDoingScreenerQuestions.value;
    let areScreenerQuestionsValid = true;

    if (isCheckingScreenerQuestions) {
      const validQuestions = copilotActivationService.screenerQuestions.filter(
        (question) => question.text.trim().length > 0,
      );

      areScreenerQuestionsValid =
        validQuestions.length >= MINIMUM_REQUIRED_SCREENER_QUESTIONS &&
        validQuestions.length <= MAXIMUN_ALLOWED_SCREENER_QUESTIONS;
    }

    // Also double check the job description validity.
    const validJobDescription = `${copilotActivationService.rawDescription}`.trim().length > 0;

    // Only validate the form if we are activating the job.
    if (
      activate &&
      (!isValidForm.value || !areScreenerQuestionsValid || !validJobDescription || !projectId.value)
    ) {
      // If the form is invalid, wait for a brief moment, find the first invalid input field, and scroll to it smoothly.
      await sleep(300);
      const firstInvalidInput = document.querySelector('.v-field--error');

      if (firstInvalidInput) {
        const rect = firstInvalidInput.getBoundingClientRect();
        const offset = window.scrollY + rect.top - 32;
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }
      return;
    }

    await copilotActivationService.updateJob();

    if (activate && projectId.value) {
      await projectService.updateProjectCopilotStatus(projectId.value, true);

      // Only track the abandoned event if copilot was not active
      if (!hasAlreadyCopilotActivated.value) {
        TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_COMPLETED, {
          project_id: projectId.value,
          job_id: copilotActivationService.selectedJobId,
        });
        // Update the local value for the UI since the DB change is done as a side effect
        copilotActivationService.jobStatus = JobStatus.LIVE;
      }
    }

    SnackbarService.success('Job updated successfully');
    await exitCopilotFlow();
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to update job. Please try again later.');
  } finally {
    isLoading.value = false;
  }
}

function handleCancelExit() {
  isExitModalOpen.value = false;
}

onMounted(() => {
  loadProjectInformation();
  canEditForm.value = true;
  // Scroll top
  window.scrollTo({ top: 0, behavior: 'instant' });
});

onBeforeMount(() => {
  jobVersionsService.resetJobVersions();
});

watch(projectId, (newValue) => {
  if (!newValue) {
    return redirectToConversations();
  }
});

watch(isFormReady, (newValue) => {
  if (newValue) {
    // Scroll top
    window.scrollTo({ top: 0, behavior: 'instant' });

    // We need to clone the localJobData to compare it later to the Form
    // data changes made by the user
    clonedLocalJobDataInformation.value = JSON.stringify(
      copilotActivationService.localJobDataInformation,
    );
  }
});

/**
 * On an attempt to leave the page, show the exit modal, else
 * allow leaving the page if the form is valid or if the exit modal is
 * already visible. When leaving the page, refresh the projects list.
 */
onBeforeRouteLeave(async (to, from, next) => {
  const page = document.getElementsByTagName('body')[0];
  page.classList.remove('noscroll');

  await copilotForm.value?.validate();

  if (isValidForm.value || isExitModalOpen.value) {
    // Only track the abandoned event if copilot is not active
    if (!hasAlreadyCopilotActivated.value) {
      TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_ABANDONED, {
        project_id: projectId.value,
        job_id: copilotActivationService.selectedJobId,
      });
    }
    await projectService.searchProjects({ skipTracking: true });
    //If we exit the FORM, we need to reset the Job Versions for the project
    jobVersionsService.currentJobVersion = null;
    jobVersionsService.currentJobVersionId = null;
    next();
    return;
  }

  isExitModalOpen.value = true;

  next(false);
});

const selectedVersionId = computed(() => {
  return jobVersionsService.currentJobVersionId;
});

const showSkeletonLoader = ref(false);

watch(selectedVersionId, async (newValue, oldValue) => {
  if (newValue === oldValue) {
    return;
  }

  showSkeletonLoader.value = true;

  if (jobVersionsService.jobVersionWithRelations) {
    // Project is empty, redirect to conversations.
    // The project should be already set by the time we get here.
    if (!project.value) {
      await redirectToConversations();
      showSkeletonLoader.value = false;
      return;
    }

    await copilotActivationService.setupStoreByProject(project.value, true);

    // If the selected ID is the same as the first version in the list then we are in normal mode.
    if (jobVersionsService.jobVersions[0].id === newValue) {
      jobVersionsService.currentJobVersionViewStatus = JobVersionViewStatus.NORMAL_MODE;
      canEditForm.value = true;
    } else {
      jobVersionsService.currentJobVersionViewStatus = JobVersionViewStatus.VIEW_MODE;
      canEditForm.value = false;
    }
  }
  showSkeletonLoader.value = false;
});

const isDisabled = computed(() => {
  return isLoading.value || jobVersionsService.isLoadingJobVersion || !canEditForm.value;
});

const isJobVersionEditMode = computed(() => {
  return jobVersionsService.currentJobVersionViewStatus === JobVersionViewStatus.EDIT_MODE;
});

const isJobVersionViewMode = computed(() => {
  return jobVersionsService.currentJobVersionViewStatus === JobVersionViewStatus.VIEW_MODE;
});
const isLoadingOneJobVersion = computed(() => jobVersionsService.isLoadingJobVersion);

// To disable scrolling when loading a job version
watch(isLoadingOneJobVersion, (newValue) => {
  const page = document.getElementsByTagName('body')[0];
  if (newValue) {
    page.classList.add('noscroll');
  } else {
    page.classList.remove('noscroll');
  }
  showSkeletonLoader.value = newValue;
});

const enableJobVersionEditing = () => {
  jobVersionsService.currentJobVersionViewStatus = JobVersionViewStatus.EDIT_MODE;
  canEditForm.value = true;
};
const disableJobVersionEditing = () => {
  jobVersionsService.currentJobVersionViewStatus = JobVersionViewStatus.VIEW_MODE;
  canEditForm.value = false;
};

const selectedJobVersionDate = computed(() => {
  if (jobVersionsService.currentJobVersion) {
    return VersionsService.formatJobVersionDate(
      new Date(jobVersionsService.currentJobVersion.createTs),
    );
  } else {
    return '';
  }
});

const clonedLocalJobDataInformation = ref('');
const isSameJobData = computed(() => {
  return (
    JSON.stringify(copilotActivationService.localJobDataInformation) ===
    clonedLocalJobDataInformation.value
  );
});

const jobStatusHistory = ref<JobStatusHistory[]>([]);
const wasCopilotActivated = computed(() => {
  return jobStatusHistory.value.some((history) => history.status === JobStatus.LIVE);
});

// If the Project was already Copilot activated (in jobStatusHistory), we should not disable the submit button
// because the user might want to activate Copilot again witout changing anything
const isSubmitDisabled = computed(() => {
  if (!hasAlreadyCopilotActivated.value && wasCopilotActivated.value) return false;
  return isDisabled.value || isSameJobData.value;
});
</script>

<template>
  <div class="h-full w-full pl-8">
    <v-navigation-drawer
      width="336"
      v-model:model-value="isHeadersDrawerOpen"
      color="shade-860"
      class="px-4 pt-8"
      :permanent="isDesktop"
    >
      <JobHeaders v-if="isFormReady" @click:back="openExitModal" />
    </v-navigation-drawer>
    <div class="flex">
      <!-- Main Content -->
      <v-form
        v-model="isValidForm"
        v-show="isFormReady"
        class="copilot-activation-form mx-auto max-w-[696px] py-7"
        ref="copilotForm"
        @submit.prevent
      >
        <template v-if="showSkeletonLoader">
          <JobDetailsFormSkeleton />
        </template>
        <template v-else>
          <div v-if="isJobVersionEditMode" class="banner restored">
            <strong>Restored version ({{ selectedJobVersionDate }})</strong>. Save this version to
            overwrite the active job posting.
          </div>
          <div v-if="isJobVersionViewMode" class="banner view-only">
            You are viewing an older version.
            <a
              href="#"
              class="font-sans text-sm font-semibold text-highlight-600 transition-colors hover:!text-highlight-800"
              @click.prevent="enableJobVersionEditing"
              >Restore</a
            >
            this version to edit it.
          </div>

          <span v-if="isJobVersionViewMode" class="float-right inline-block">
            <v-btn
              :ripple="false"
              class="modal-button-secondary"
              variant="flat"
              @click.prevent="enableJobVersionEditing"
              >Restore to edit</v-btn
            >
          </span>
          <JobDetailsForm :is-disabled="isDisabled" />
          <JobSkillsForm :is-disabled="isDisabled" />
          <JobDescriptionForm
            @init:editor="loadedRequirements.jobDescriptionForm = true"
            :disabled="isDisabled"
          />
          <CandidateScreeningForm
            :is-disabled="isDisabled"
            v-if="isDoingCandidateScreening && !isDoingZeroScreening"
          />
          <ScreenerQuestionsForm :is-disabled="isDisabled" v-if="isDoingScreenerQuestions" />
          <div v-if="!isJobVersionViewMode">
            <v-btn
              :ripple="false"
              :disabled="isSubmitDisabled"
              :loading="isLoading"
              class="modal-button-primary"
              variant="flat"
              @click.prevent="updateJob({ activate: true })"
              >{{ updateBtnText }}</v-btn
            >
            <v-btn
              v-if="isJobVersionEditMode"
              :ripple="false"
              class="modal-button-text ml-4"
              variant="flat"
              @click.prevent="disableJobVersionEditing"
              >Cancel</v-btn
            >

            <!-- Additional charge notice -->
            <div
              v-if="isShowingAdditionalChargeNotice"
              class="relative ml-12 mt-9 min-h-[95px] max-w-sm rounded-lg bg-caution-50 p-4 pl-12 font-sans text-sm font-normal leading-5 text-caution-800"
              :class="{ '!ml-0 mt-10 w-full': !isDesktop }"
            >
              <div class="absolute left-4 top-4">
                <AlertTriangleIcon />
              </div>
              <div class="inline">
                <span class="font-bold">Your current plan is at capacity.</span>
                Activating Copilot on this job will incur additional charges.
              </div>
            </div>
          </div>
        </template>
      </v-form>
      <div
        v-show="isFormReady"
        class="ml-8 w-[305px] min-w-[305px] border-l-[1px] border-tint-40 p-6"
      >
        <p class="mb-4 font-sans text-lg font-extrabold leading-7 text-black">Version History</p>
        <div v-if="isJobVersionEditMode" class="restored-list-item">
          Restored version ({{ selectedJobVersionDate }})
          <p>Unpublished</p>
        </div>
        <VersionHistoryList
          :key="projectId"
          :is-job-version-edit-mode="isJobVersionEditMode"
          :project-id="projectId"
          :is-copilot-activated="hasAlreadyCopilotActivated || wasCopilotActivated"
        />
      </div>
    </div>
    <div v-show="!isFormReady" class="flex h-full w-full items-center justify-center">
      <SpinnerLoader />
    </div>

    <!-- Exit modal -->
    <ExitCopilotActivationModal
      :model-value="isExitModalOpen"
      @cancel="handleCancelExit"
      @exit="exitCopilotFlow"
    />
  </div>
</template>
<style lang="postcss" scoped>
:deep(.v-field--disabled) {
  @apply border-[1px] border-solid border-tint-80 bg-tint-40 opacity-85;
}
.banner {
  @apply mb-6 rounded-lg p-4 text-sm;
  &.restored {
    @apply bg-caution-50 text-caution-800;
  }
  &.view-only {
    @apply border border-solid  border-tint-60 bg-tint-20;
  }
}

.restored-list-item {
  @apply relative mb-[18px] rounded-lg border-[1px] border-solid border-inform-50 bg-inform-0 p-4 text-sm font-bold;
  &:after {
    @apply pointer-events-none cursor-default transition-all duration-150;
    @apply absolute bottom-[-10px] left-0 right-0 border-b-[1px] border-tint-60;
    content: '';
  }

  p {
    @apply font-normal italic text-shade-800;
  }
}
</style>
<style lang="postcss">
.noscroll {
  width: 100%;
  position: fixed !important;
}
/** We also want to disable the DOWN chevron from the Autocomplete and combobox */
.copilot-activation-form {
  .v-field--disabled {
    .v-combobox__menu-icon,
    .v-autocomplete__menu-icon {
      display: none;
    }
  }
  .disabled {
    .v-combobox__menu-icon,
    .v-autocomplete__menu-icon {
      display: none;
    }
  }
  .v-input--disabled {
    .v-input__prepend {
      display: none;
    }
    .v-input__append {
      display: none;
    }
  }
}
</style>
