<script setup lang="ts">
import PlusSvg from '@/assets/svg/plus-16.svg?component';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { DialogService } from '@/core/shared/dialog/dialog.service';
import ProjectService from '@/core/shared/project/project.service';
import ProjectSearchFilters from '@/components/Shared/Projects/ProjectSearchFilters.vue';
import { PageSource } from '@/core/shared/tracking/tracking.type';
import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ProjectRemovedFromDeeplink from '@/components/Shared/Projects/ProjectRemovedFromDeeplink.vue';
import ProjectDescriptionOverlay from '@/components/Shared/Projects/ProjectDescriptionOverlay.vue';
import ProjectList from '@/components/Shared/Projects/ProjectList.vue';

const route = useRoute();
const router = useRouter();
const dialogService = new DialogService();
const projectService = new ProjectService();

const hasProjects = computed(() => {
  return projectService.projects.length > 0;
});

const pageSource = computed<PageSource>(() => {
  if (typeof route.name !== 'string') {
    return PageSource.Conversations;
  }

  const lowerCaseRouteName = route.name.toLowerCase();
  const includesSourcing = lowerCaseRouteName.includes('sourcing');

  if (includesSourcing) {
    return PageSource.Sourcing;
  }

  return PageSource.Conversations;
});

function openCreateJobDialog() {
  dialogService.isCreateProjectDialogOpen = true;
  TrackingService.trackAction(TrackingActionName.JOB_STARTED);
}

async function handleFiltersChange() {
  try {
    await projectService.searchProjects({
      skipOnSameSearch: true,
      skipTracking: false,
      pageSource: pageSource.value,
    });

    await projectService.searchAvailableProjects();
    await handleProjectsInQueryParams();
    await handleNoSelectedProjectState();
    projectService.didLoadProjects = true;
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to load jobs');
  }
}

async function handleProjectsInQueryParams() {
  const query = route.query;
  let projects: number[] = [];

  if (typeof query.projects === 'string') {
    projects = [Number(query.projects)];
  }

  if (Array.isArray(query.projects)) {
    projects = query.projects.map(Number);
  }

  const validProjectIds = projects.filter((id) => !isNaN(id));

  if (validProjectIds.length === 0) {
    return;
  }

  const firstValidProjectId = validProjectIds[0];
  await handleProjectChange(firstValidProjectId);
}

async function handleNoSelectedProjectState() {
  if (hasProjects.value && !projectService.currentProject?.id) {
    await handleProjectChange(projectService.projects[0].id);
  }
}

async function handleProjectChange(projectId: number) {
  await projectService.getProjectById(projectId);

  if (pageSource.value === PageSource.Sourcing) {
    await router.push({
      name: 'sourcing-job-id',
      params: { id: `${projectId}` },
    });
  }
}
</script>
<template>
  <!-- New Job Button -->
  <div class="flex items-center justify-between px-4 pt-[18px]">
    <div class="font-serif text-base font-black leading-6">Saved jobs</div>

    <button
      class="flex items-center justify-center rounded bg-highlight-500 px-2 py-[5px] text-xs font-bold leading-[18px] text-white"
      @click="openCreateJobDialog"
    >
      <PlusSvg class="mr-1" />
      New
    </button>
  </div>

  <!-- Project Search Filters -->
  <div class="mx-4 mt-6">
    <ProjectSearchFilters @change:filters="handleFiltersChange" />
  </div>

  <!-- Project List -->
  <div
    v-if="!projectService.isProjectFromDeepLinkRemoved"
    class="jobs-drawer-scrollbar mt-2 h-[calc(100vh-156px)] overflow-y-auto px-4"
  >
    <ProjectList
      :project-id="projectService.currentProject?.id"
      @change:project-id="handleProjectChange"
    />
  </div>

  <div
    v-if="projectService.isProjectFromDeepLinkRemoved && !projectService.isSearchingProjects"
    class="px-1 pb-2"
  >
    <ProjectRemovedFromDeeplink />
  </div>

  <ProjectDescriptionOverlay />
</template>

<style scoped>
/** Scrollbar width */
.jobs-drawer-scrollbar::-webkit-scrollbar {
  width: 5px;
}

/** Scrollbar color and border radius */
.jobs-drawer-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-shade-600;
  border-radius: 10px;
}
</style>
